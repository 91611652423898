

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RiDeleteBin6Fill } from 'react-icons/ri';

const initialValue = {
    cityId: '',
    routeName: '',
};

export default function RoutesCityForm() {
    const [data, setData] = useState(initialValue);
    const [printData, setPrintData] = useState([]);
    const [routeData, setRouteData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null); // New state to hold the edit index

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };




    // Handle Update Route
    const handleUpdate = async () => {
        try {
            const updateEndpoint = `https://bhadegadiapi.taxiwalataxi.in/api/v1/editroute/${data._id}/${editIndex}`; // Use editIndex
            const response = await fetch(updateEndpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    routeName: data.routeName,
                }),
            });

            const jsonResponse = await response.json();
            if (jsonResponse.status === 'success') {
                setEdit(false);
                setData(initialValue); // Reset form
                toast.success('Updated Route Data Successfully');
                fetchRouteData();  
            } else {
                toast.error(jsonResponse.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to update Route data');
        }
    };

    // Handle Submit new Route
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data.routeName) {
            toast.error('Route name is required');
            return;
        }
        try {
            const addRecordEndpoint = `https://bhadegadiapi.taxiwalataxi.in/api/v1/addroute?cityId=${data.cityId}`;
            const response = await fetch(addRecordEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    routeName: data.routeName,
                }),
            });

            const jsonResponse = await response.json();
            if (jsonResponse.status === 'success') {
                fetchRouteData();
                setData(initialValue);
                toast.success('Route Added Successfully');
            } else {
                toast.error(jsonResponse.message);
            }
        } catch (err) {
            console.error(err);
            toast.error('Failed to add Route');
        }
    };

    // Fetch data to display routes
    const fetchPrintData = async () => {
        try {
            const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/getroute');
            const jsonResponse = await response.json();
            if (jsonResponse.status === 'success') {
                setPrintData(jsonResponse.data.cityname || []);
            } else {
                toast.error('Failed to fetch routes');
            }
        } catch (err) {
            console.error(err);
            toast.error('Failed to fetch city data');
        }
    };

    // Fetch data to display routes
    const fetchRouteData = async () => {
        try {
            const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/getroutebycity');
            const jsonResponse = await response.json();
            if (jsonResponse.status === 'success') {
                setRouteData(jsonResponse.data.cityname || []);
            } else {
                toast.error('Failed to fetch routes');
            }
        } catch (err) {
            console.error(err);
            toast.error('Failed to fetch city data');
        }
    };

    // Edit Route Handler
    // Edit Route Handler
    const handleEdit = (route, index) => {
        setData({
            cityId: route.cityId,
            routeName: route.routeNames[index],
            _id: route._id, // Set the route ID to data state
        });
        setEdit(true);
        setEditIndex(index); // Set the index of the route to edit
    };

    useEffect(() => {
        fetchPrintData();
        fetchRouteData();
    }, []);

    console.log('printData =>', printData);


    // Handle Delete Route
    const handleDelete = async (cityId, index) => {
        try {
            const response = await fetch(`https://bhadegadiapi.taxiwalataxi.in/api/v1/routdelete/${cityId}/${index}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                // Fetch data after deletion
                toast.success('Deleted Route Successfully');
                setData(initialValue);
                // setData((values) => values.filter((item) => item._id !== id));
                fetchRouteData();
            } else {
                const jsonResponse = await response.json();
                toast.error(jsonResponse.message || 'Failed to delete Route');
            }
        } catch (err) {
            console.error(err);
            toast.error('Failed to delete Route');
        }
    };



    return (
        <main className="main-container">
            <h1 className="text-black text-[20px] py-3 px-8">Route Form</h1>

            <form onSubmit={handleSubmit}>
                <div className="w-full max-w-lg mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
                    {/* City Select Dropdown */}
                    {edit ? null : <div className="py-2">
                        <select
                            name="cityId"
                            className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                            onChange={handleChange}
                            value={data.cityId}
                        >
                            <option value="">Select City</option>
                            {printData.map(city => (
                                <option key={city._id} value={city._id}>
                                    {city.cityname}
                                </option>
                            ))}
                        </select>
                    </div>}

                    {/* Route Name Field */}
                    <div className="py-2">
                        <input
                            className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                            type="text"
                            name="routeName"
                            placeholder="Route Name"
                            onChange={handleChange}
                            value={data.routeName}
                        />
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-center gap-4 mt-4">
                        {edit ? (
                            <button
                                type="button"
                                className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                                onClick={handleUpdate}
                            >
                                Update
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="bg-[#188ae2] text-white hover:bg-[#1899ff] rounded-lg px-4 py-2"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            </form>

            {/* Display Data Table */}

            <div className="w-full max-w-[70rem] mx-auto pt-3 bg-gray-100 mt-8 shadow-2xl rounded-lg border border-gray-300">
                <h2 className="text-3xl ml-6 font-bold text-gray-900">Routes</h2>
                <div className="p-6">
                    <div className="text-black">
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md ">
                            {routeData.length > 0 ? (
                                routeData.map((route, index) => (
                                    <div key={route._id} className="bg-white p-4 border rounded-lg shadow-md my-3">
                                        <h2 className="text-xl font-semibold mb-2">City: {route.cityName}</h2>
                                        <div>
                                            <p className="text-gray-700 mb-2 font-bold">Routes:</p>
                                            <ul className="list-disc pl-5">
                                                {route.routeNames.map((routeName, idx) => (
                                                    <li key={idx} className="text-gray-700 flex justify-between items-center">
                                                        <span className="flex-grow">{routeName}</span>
                                                        <div className="flex ml-2">
                                                            <button
                                                                onClick={() => handleEdit(route, idx)}
                                                                className="text-blue-600 hover:underline ml-2"
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                onClick={() => handleDelete(route._id, idx)} // Pass city ID and index
                                                                className="text-red-600 hover:underline ml-2"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        
                                    </div>
                                ))
                            ) : (
                                <div className="bg-white p-4 border rounded-lg shadow-md my-3 text-center">
                                    <p>No routes available</p>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
