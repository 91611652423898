import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify } from 'react-icons/bs';
import { toast } from 'react-toastify';
import AddTaxidetails from '../Forms/addTaxiDetails';

function Header({ OpenSidebar, setFormOpen, formOpen }) {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate('/');
    toast.success("Logout successfully");
    window.location.reload();
  };

  const handleAddCar = () => {
    setFormOpen(true)
    navigate('/addcar');
  };

  return (
    <header className="header bg-[#188ae2] flex items-center justify-between p-4">
      <div className="menu-icon">
        <BsJustify size={20} fill="#fff" onClick={OpenSidebar} />
      </div>
      <div className="header-left">
        <button
          className="border-2 text-[#fff] hover:bg-[#fff] hover:text-[#188ae2] 
                     px-3 py-1 sm:px-4  lg:px-5  xl:px-6  
                     text-sm sm:text-base lg:text-lg xl:text-xl rounded-xl 
                     shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
          onClick={handleAddCar}
        >
          Add Car
        </button>
      </div>
      <div className="header-right flex space-x-4 sm:space-x-6 lg:space-x-8">
        <button
          className="border-2 text-[#fff] hover:bg-[#fff] hover:text-[#188ae2] 
                     px-3 py-1 sm:px-4  lg:px-5  xl:px-6  
                     text-sm sm:text-base lg:text-lg xl:text-xl rounded-xl 
                     shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      {formOpen && <AddTaxidetails setFormOpen={setFormOpen} />}
    </header>
  );
}

export default Header;
