import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddTaxidetails = ({ setFormOpen }) => {

    const [localFormData, setLocalFormData] = useState({
        Name: '',
        icon: '',
        Image: '',
        BootSpace: '',
        seats: '',
        Type: '',
        IsAcCar: '',
        onewayTrip: {
            isAvailable: '',
            actualRatePerKM: '',
            discountRatePerKM: ''
        },
        roundTrip: {
            isAvailable: '',
            actualRatePerKM: '',
            discountRatePerKM: '',
            perDayKMLimit: ''
        },
        eightHours: {
            isAvailable: '',
            actualRateFix: '',
            discountRateFix: '',
            perKMRateOneway: '',
            bookingKMLimit: ''
        },
        tenHours: {
            isAvailable: '',
            actualRateFix: '',
            discountRateFix: '',
            perKMRateOneway: '',
            bookingKMLimit: ''
        },
        twelveHours: {
            isAvailable: '',
            actualRateFix: '',
            discountRateFix: '',
            perKMRateOneway: '',
            bookingKMLimit: ''
        },
        airportTrip: {
            isAvailable: '',
            actualRatePerKM: '',
            discountRatePerKM: ''
        }
    });

    const [imagePreview, setImagePreview] = useState(null);
    const [iconPreview, setIconPreview] = useState(null);
    const [taxiPrint, setTaxiPrint] = useState([]);
    const [selectPrint, setSelectPrint] = useState('');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                if (name === 'Image') {
                    setImagePreview(reader.result);
                } else if (name === 'icon') {
                    setIconPreview(reader.result);
                }
            };
            reader.readAsDataURL(file);
            setLocalFormData({
                ...localFormData,
                [name]: file.name // Store the file name, not the full file path
            });
        } else {
            const keys = name.split('.');
            if (keys.length > 1) {
                setLocalFormData(prevState => {
                    return {
                        ...prevState,
                        [keys[0]]: {
                            ...prevState[keys[0]],
                            [keys[1]]: value
                        }
                    }
                });
            } else {
                setLocalFormData({
                    ...localFormData,
                    [name]: value
                });
            }
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('Name', selectPrint);

        // Ensure file input fields are correctly referenced
        const iconFile = document.querySelector('input[name="icon"]').files[0];
        const imageFile = document.querySelector('input[name="Image"]').files[0];

        if (iconFile) {
            formData.append('icon', iconFile);
        } else {
            console.error('Icon file is missing.');
        }

        if (imageFile) {
            formData.append('Image', imageFile);
        } else {
            console.error('Image file is missing.');
        }

        formData.append('BootSpace', localFormData.BootSpace);
        formData.append('seats', localFormData.seats);
        formData.append('Type', localFormData.Type);
        formData.append('IsAcCar', localFormData.IsAcCar);
        formData.append('onewayTrip', JSON.stringify(localFormData.onewayTrip));
        formData.append('roundTrip', JSON.stringify(localFormData.roundTrip));
        formData.append('eightHours', JSON.stringify(localFormData.eightHours));
        formData.append('tenHours', JSON.stringify(localFormData.tenHours));
        formData.append('twelveHours', JSON.stringify(localFormData.twelveHours));
        formData.append('airportTrip', JSON.stringify(localFormData.airportTrip));

        try {
            const response = await axios.post('https://bhadegadiapi.taxiwalataxi.in/api/v1/addnewtaxidetails', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response) {
                toast.success('Taxi details added successfully!');
                setLocalFormData({
                    Name: '',
                    icon: '',
                    Image: '',
                    BootSpace: '',
                    seats: '',
                    Type: '',
                    IsAcCar: '',
                    onewayTrip: {
                        isAvailable: '',
                        actualRatePerKM: '',
                        discountRatePerKM: ''
                    },
                    roundTrip: {
                        isAvailable: '',
                        actualRatePerKM: '',
                        discountRatePerKM: '',
                        perDayKMLimit: ''
                    },
                    eightHours: {
                        isAvailable: '',
                        actualRateFix: '',
                        discountRateFix: '',
                        perKMRateOneway: '',
                        bookingKMLimit: ''
                    },
                    tenHours: {
                        isAvailable: '',
                        actualRateFix: '',
                        discountRateFix: '',
                        perKMRateOneway: '',
                        bookingKMLimit: ''
                    },
                    twelveHours: {
                        isAvailable: '',
                        actualRateFix: '',
                        discountRateFix: '',
                        perKMRateOneway: '',
                        bookingKMLimit: ''
                    },
                    airportTrip: {
                        isAvailable: '',
                        actualRatePerKM: '',
                        discountRatePerKM: ''
                    }
                });
                setImagePreview(null);
                setIconPreview(null);
            } else {
                toast.error('Failed to add taxi details');
            }
        } catch (error) {
            console.error('Error adding taxi details:', error);
            toast.error('Error adding taxi details');
        }
    };


    const handleCancel = () => {
        setFormOpen(false);
    };



    const fetchTaxiData = async () => {
        try {
            const response = await axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/gettaxidetail');
            if (response.data.status === 'success') {
                setTaxiPrint(response.data.data);
            } else {
                // toast.error('Failed to fetch taxi data');
            }
        } catch (error) {
            console.error('Error fetching taxi data', error);
            // toast.error('Error fetching taxi data');
        }
    };


    const fatch = async () => {
        try {
            const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/allTaxi";
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const response = await fetch(addRecordEndpoint, options);
            const jsonResponse = await response.json();
            setTaxiPrint(jsonResponse?.data);
        } catch (err) {
            console.log(err);
        }
    };


    useEffect(() => {
        fatch();
        fetchTaxiData();

    }, []);

    const onOptionChangeHandler = (e) => {
        setSelectPrint(e.target.value);
    };

    return (
        <div className="main-container flex justify-center">
            <div className="px-2 mt-2 w-full max-w-7xl">
                <h2 className="text-2xl font-bold mb-4 text-black">Add Details In Taxi</h2>

                <form onSubmit={handleSubmit} >
                    <div className="grid grid-cols-6 gap-7 text-black justify-center">
                        <div className="col-span-2">
                            <label className="block text-gray-600 font-bold">Taxi Name</label>
                            <select value={selectPrint} onChange={onOptionChangeHandler} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]">
                                {taxiPrint?.map((option, index) => (
                                    <>
                                        <option value="">Select AC Option</option>
                                        <option key={index} name="Name" value={option.taxi_name} className='text-[#000]'>
                                            {option?.taxi_name}
                                        </option>
                                    </>

                                ))}
                            </select>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">AC</label>
                            <select name="IsAcCar" value={localFormData.IsAcCar} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]">
                                <option value="">Select AC Option</option>
                                <option value="AC">AC</option>
                                <option value="Non AC">Non AC</option>
                            </select>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">Type</label>
                            <select name="Type" value={localFormData.Type} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]">
                                <option value="">Type</option>
                                <option value="Sedan">Sedan</option>
                                <option value="Crysta">Crysta</option>
                                <option value="17-Seat">17-Seat</option>
                                <option value="14-Seat">14-Seat</option>
                                <option value="20-Seat">20-Seat</option>
                                <option value="Winger">Winger</option>
                                <option value="Urbania">Urbania</option>
                            </select>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">Seats</label>
                            <input name="seats" value={localFormData.seats} onChange={handleChange} type="number" className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]" />
                        </div>
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">Boot Space</label>
                            <input name="BootSpace" value={localFormData.BootSpace} onChange={handleChange} type="number" className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]" />
                        </div>
                        <div className="col-span-2">
                            <label className="block text-gray-600 font-bold">Car Image</label>
                            <input name="Image" onChange={handleChange} type="file" accept="image/*" className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]" />
                            {imagePreview && (
                                <img src={imagePreview} alt="Car Preview" className="mt-2 h-32" />
                            )}
                        </div>
                        <div className="col-span-2">
                            <label className="block text-gray-600 font-bold">Icon Image</label>
                            <input name="icon" onChange={handleChange} type="file" accept="image/*" className="w-full border border-gray-400 p-1 focus:outline-none rounded-md text-[#000]" />
                            {iconPreview && (
                                <img src={iconPreview} alt="Icon Preview" className="mt-2 h-32" />
                            )}
                        </div>
                        {/* Trip Details */}
                        <div className="col-span-6">
                            <div className="mt-5 "></div>

                            <div className="grid grid-cols-6 gap-2 text-black">

                                {/* Oneway Trip */}
                                <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold '>OnewayTrip Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                        <select name="onewayTrip.isAvailable" value={localFormData.onewayTrip.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="onewayTrip.actualRatePerKM" value={localFormData.onewayTrip?.actualRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate </label>
                                        <input type="number" name="onewayTrip.discountRatePerKM" value={localFormData.onewayTrip?.discountRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>

                                </div>

                                {/* Round Trip */}
                                <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold '>Round Trip Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm">Availability</label>
                                        <select name="roundTrip.isAvailable" value={localFormData.roundTrip?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="roundTrip.actualRatePerKM" value={localFormData.roundTrip?.actualRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1"> Discount Rate Per KM Limit </label>
                                        <input type="number" name="roundTrip.discountRatePerKM" value={localFormData.roundTrip?.discountRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Booking Km Limit  </label>
                                        <input type="number" name="roundTrip.perDayKMLimit" value={localFormData.roundTrip?.perDayKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>

                                </div>

                                {/* Eight Hours */}
                                <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold '>Eight Hours Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                        <select name="eightHours.isAvailable" value={localFormData.eightHours?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="eightHours.actualRateFix" value={localFormData.eightHours?.actualRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate </label>
                                        <input type="number" name="eightHours.discountRateFix" value={localFormData.eightHours?.discountRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Rate Oneway </label>
                                        <input type="number" name="eightHours.perKMRateOneway" value={localFormData.eightHours?.perKMRateOneway || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Booking Km Limit </label>
                                        <input type="number" name="eightHours.bookingKMLimit" value={localFormData.eightHours?.bookingKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                </div>

                                {/* Ten Hours */}
                                <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold '>Ten Hours Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                        <select name="tenHours.isAvailable" value={localFormData.tenHours?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="tenHours.actualRateFix" value={localFormData.tenHours?.actualRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate </label>
                                        <input type="number" name="tenHours.discountRateFix" value={localFormData.tenHours?.discountRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Rate Oneway </label>
                                        <input type="number" name="tenHours.perKMRateOneway" value={localFormData.tenHours?.perKMRateOneway || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Bokking Limit</label>
                                        <input type="number" name="tenHours.bookingKMLimit" value={localFormData.tenHours?.bookingKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                </div>

                                {/* Twelve Hours */}
                                <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold '>Twelve Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                        <select name="twelveHours.isAvailable" value={localFormData.twelveHours?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2 ">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="twelveHours.actualRateFix" value={localFormData.twelveHours?.actualRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate </label>
                                        <input type="number" name="twelveHours.discountRateFix" value={localFormData.twelveHours?.discountRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Rate Oneway </label>
                                        <input type="number" name="twelveHours.perKMRateOneway" value={localFormData.twelveHours?.perKMRateOneway || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1 mb-3">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Booking Limit</label>
                                        <input type="number" name="twelveHours.bookingKMLimit" value={localFormData.twelveHours?.bookingKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                </div>

                                {/* Airport Trip */}
                                <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold text-sm py-1'>Airport Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                        <select name="airportTrip.isAvailable" value={localFormData.airportTrip?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="airportTrip.actualRatePerKM" value={localFormData.airportTrip?.actualRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate </label>
                                        <input type="number" name="airportTrip.discountRatePerKM" value={localFormData.airportTrip?.discountRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-6 flex justify-end space-x-2">

                            <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded-md" onClick={handleCancel}>Cancel</button>

                            {/* <button type="reset" className="bg-gray-500 text-white px-4 py-2 rounded-md">Reset</button> */}
                            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">Submit</button>
                        </div>
                    </div>
                </form>


            </div>
        </div>

    )
}

export default AddTaxidetails;