import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentInfo = () => {
    const [paymentList, setPaymentList] = useState([]); // State to hold the payment info
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        // Fetch the list of payments
        axios
            .get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getpaymentinfo')
            .then(response => {
                setPaymentList(response.data.data.cityname); // Assuming this is the correct data path
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                console.error('Error fetching payment info:', error);
                setError('Error fetching payment info');
                setLoading(false); // Set loading to false in case of error
            });
    }, []);

    if (loading) {
        return <h1>Loading...</h1>; // Loading state
    }

    if (error) {
        return <h1>{error}</h1>; // Error state
    }

    return (
        <main className="main-container">
            <div className="w-full h-screen">
                <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">

                    <div className="flex flex-col">

                       
                        <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
            <h1 className='text-black py-5 text-xl '> Payment Details </h1>

                            <div className="align-middle inline-block w-full shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400">
                          
                                <table className="min-w-full text-center">
                                    <thead className="text-center">
                                        <tr className="bg-gray-50 text-xs leading-4 text-gray-700 uppercase tracking-wider border-b border-gray-400">
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">ID</th>
                                            {/* <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Transaction ID</th> */}
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">CarName</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Name</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Amount</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">PhoneNo</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Email</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Payment Method</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white text-center">
                                        {paymentList.length > 0 ? (
                                            paymentList.map((contact, i) => (
                                                <tr key={i} className="border border-gray-400">
                                                    <td className="text-black border-r border-gray-400">{i + 1}</td>
                                                    {/* <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400 font-normal">
                                                        <div className="text-sm leading-5 text-black">{contact.orderId}</div>
                                                    </td> */}
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                        <div className="text-sm leading-5 text-black">{contact.car_name}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                        <div className="text-sm leading-5 text-black">{contact.user_name}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                        <div className="text-sm leading-5 text-black">{contact.amount}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                        <div className="text-sm leading-5 text-black">{contact.phoneno}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                        <div className="text-sm leading-5 text-black">{contact.email}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                        <div className="text-sm leading-5 text-black">{contact.paymentMethod}</div>
                                                    </td>
                                                   
                                                    <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400 font-normal">
                                                        <div className="text-sm leading-5 text-black">{new Date(contact.createdAt).toLocaleString()}</div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">
                                                    No payment information available.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PaymentInfo;
