import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditModal from '../Forms/Edittaxidetail'; // Import the modal component

const TaxiDetail = () => {
    let { id } = useParams();
    const [allDataTable, setAllDataTable] = useState([]);
    const [taxiData, setTaxiData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});

    const fetchData = async () => {
        try {
            const addRecordEndpoint = "https://bhadegadiapi.taxiwalataxi.in/api/v1/gettaxi";
            const response = await fetch(addRecordEndpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const jsonResponse = await response.json();
            setAllDataTable(jsonResponse.data.taxis);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (allDataTable.length > 0) {
            const matchedTaxi = allDataTable.find(taxi => taxi._id === id);
            setTaxiData(matchedTaxi);
            if (matchedTaxi) {
                setFormData(matchedTaxi); // Initialize form data
            }
        }
    }, [allDataTable, id]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCloseModal = async (updatedData) => {
        setIsEditing(false);
        try {
            // Send updated data to backend
            const updateEndpoint = `https://bhadegadiapi.taxiwalataxi.in/api/v1/updatetaxi/${id}`;
            await fetch(updateEndpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });
            // Refetch data to update state
            console.log('updatedData =>',updatedData);
            
            await fetchData();
        } catch (err) {
            console.log(err);
        }
    };

    if (!taxiData) return <div>Loading...</div>;




    return (
        <main className="main-container p-4">
            <div className="w-full max-w-[70rem] mx-auto pt-3 bg-gray-100 my-6 shadow-2xl rounded-lg border border-gray-300">
                <div className="flex md:flex-row justify-between items-center p-6">
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-900">{formData.Name}</h2>
                    <button
                        onClick={handleEditClick}
                        className="bg-blue-500 text-white px-3 py-2 md:px-4 md:py-2 rounded-md hover:bg-blue-600 mt-4 md:mt-0"
                    >
                        Edit
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6 text-gray-800">
                    <div className="flex-col justify-center bg-gray-200 p-4 rounded-lg shadow-md">
                        <p className="mb-3"><strong>Boot Space:</strong> {formData.BootSpace}</p>
                        <p className="mb-3"><strong>Seats:</strong> {formData.seats}</p>
                        <p className="mb-3"><strong>Type:</strong> {formData.Type}</p>
                        <p className="mb-3"><strong>AC Car:</strong> {formData.IsAcCar ? 'Yes' : 'No'}</p>
                    </div>
                    <div className="flex justify-center items-center">
                        <img
                            src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${formData.Image}`}
                            alt="Taxi"
                            className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                    </div>
                    <div className="flex justify-center items-center">
                        <img
                            src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${formData.icon}`}
                            alt="Taxi Icon"
                            className="w-[7rem] h-[13rem] md:w-[7rem] md:h-[13.3rem] object-cover "
                        />
                    </div>
                </div>
            </div>

            <div className="w-full max-w-[70rem] mx-auto pt-3 bg-gray-100 mt-8 shadow-2xl rounded-lg border border-gray-300">
                <h2 className="text-2xl md:text-3xl ml-6 font-bold text-gray-900">{formData.Name} : Trip Type Details</h2>
                <div className="p-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-black">

                        {/* Oneway Trip */}
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg md:text-xl font-semibold mb-2">One Way Trip :</h4>
                            <div className="flex justify-between mb-1">
                                <strong>Available:</strong>
                                <span>{formData.onewayTrip.isAvailable}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Actual Rate per KM:</strong>
                                <span>{formData.onewayTrip.actualRatePerKM}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Discount Rate per KM:</strong>
                                <span>{formData.onewayTrip.discountRatePerKM}</span>
                            </div>
                        </div>

                        {/* Round Trip */}
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg md:text-xl font-semibold mb-2">Round Trip :</h4>
                            <div className="flex justify-between mb-1">
                                <strong>Available:</strong>
                                <span>{formData.roundTrip.isAvailable}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Actual Rate per KM:</strong>
                                <span>{formData.roundTrip.actualRatePerKM}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Discount Rate per KM:</strong>
                                <span>{formData.roundTrip.discountRatePerKM}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Per Day KM Limit:</strong>
                                <span>{formData.roundTrip.perDayKMLimit} KM</span>
                            </div>
                        </div>

                        {/* Eight Hours */}
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg md:text-xl font-semibold mb-2">Eight Hours :</h4>
                            <div className="flex justify-between mb-1">
                                <strong>Available:</strong>
                                <span>{formData.eightHours.isAvailable}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Actual Rate Fix:</strong>
                                <span>{formData.eightHours.actualRateFix}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Discount Rate Fix:</strong>
                                <span>{formData.eightHours.discountRateFix}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Per KM Rate (One Way):</strong>
                                <span>{formData.eightHours.perKMRateOneway}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Booking KM Limit:</strong>
                                <span>{formData.eightHours.bookingKMLimit} KM</span>
                            </div>
                        </div>

                        {/* Ten Hours */}
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg md:text-xl font-semibold mb-2">Ten Hours :</h4>
                            <div className="flex justify-between mb-1">
                                <strong>Available:</strong>
                                <span>{formData.tenHours.isAvailable}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Actual Rate Fix:</strong>
                                <span>{formData.tenHours.actualRateFix}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Discount Rate Fix:</strong>
                                <span>{formData.tenHours.discountRateFix}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Per KM Rate (One Way):</strong>
                                <span>{formData.tenHours.perKMRateOneway}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Booking KM Limit:</strong>
                                <span>{formData.tenHours.bookingKMLimit} KM</span>
                            </div>
                        </div>

                        {/* Twelve Hours */}
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg md:text-xl font-semibold mb-2">Twelve Hours :</h4>
                            <div className="flex justify-between mb-1">
                                <strong>Available:</strong>
                                <span>{formData.twelveHours.isAvailable}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Actual Rate Fix:</strong>
                                <span>{formData.twelveHours.actualRateFix}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Discount Rate Fix:</strong>
                                <span>{formData.twelveHours.discountRateFix}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Per KM Rate (One Way):</strong>
                                <span>{formData.twelveHours.perKMRateOneway}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Booking KM Limit:</strong>
                                <span>{formData.twelveHours.bookingKMLimit} KM</span>
                            </div>
                        </div>

                        {/* Airport Trip */}
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg md:text-xl font-semibold mb-2">Airport Trip :</h4>
                            <div className="flex justify-between mb-1">
                                <strong>Available:</strong>
                                <span>{formData.airportTrip.isAvailable}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Actual Rate per KM:</strong>
                                <span>{formData.airportTrip.actualRatePerKM}</span>
                            </div>
                            <div className="flex justify-between mb-1">
                                <strong>Discount Rate per KM:</strong>
                                <span>{formData.airportTrip.discountRatePerKM}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {isEditing && (
                <EditModal formData={formData} handleClose={handleCloseModal} />
            )}
        </main>

    );
};

export default TaxiDetail;
