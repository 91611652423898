import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, Outlet } from 'react-router-dom';
import Logo from '../Images/user.PNG';
import { BiSolidDashboard } from 'react-icons/bi';
import { BsLayersFill } from 'react-icons/bs';
import { FaWpforms, FaAngleDown } from 'react-icons/fa';
import { AiFillCar } from 'react-icons/ai';
import { HiLocationMarker } from 'react-icons/hi';
import { MdTour } from 'react-icons/md';
import { RiMemoriesFill } from "react-icons/ri";
import EditModal from '../Forms/Edittaxidetail';
import Contactform from '../Forms/ContactForm';
import MemoryImg from '../Forms/MemoryImg';
import GoogalemapApi from '../Forms/Googlemapapikey'

function Sidebar({ openSidebarToggle, setOpenSidebarToggle }) {
    const [showImgIcon, setShowImgIcon] = useState(null);
    const [allDataTable, setAllDataTable] = useState([]);
    const [taxiPrint, setTaxiPrint] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedTripType, setSelectedTripType] = useState('');
    const [filteredCars, setFilteredCars] = useState([]);
    const [nestedDropdownOpen, setNestedDropdownOpen] = useState('');

    const handleMenuClick = () => {
        if (window.innerWidth <= 768) {
            setOpenSidebarToggle(false);
        }
    };

    const fetchData = async () => {
        try {
            const addRecordEndpoint = "https://bhadegadiapi.taxiwalataxi.in/api/v1/gettaxi";
            const response = await fetch(addRecordEndpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const jsonResponse = await response.json();
            console.log('jsonResponse =>', jsonResponse);

            setAllDataTable(jsonResponse.data.taxis);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchTaxiNames = async () => {
        try {
            const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/allTaxi";
            const response = await fetch(addRecordEndpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const jsonResponse = await response.json();
            setTaxiPrint(jsonResponse?.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchTaxiNames();
        fetchData();
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleTripTypeSelect = (tripType) => {
        setSelectedTripType(tripType);
        if (!allDataTable) return;

        const filtered = allDataTable.filter(taxi => {
            return taxi[tripType]?.isAvailable === "Yes";
        });
        console.log(`Filtered taxis for ${tripType}: `, filtered);
        setFilteredCars(filtered);
        setNestedDropdownOpen(tripType); // Set the active nested dropdown
    };

    const toggleNestedDropdown = (tripType, event) => {
        event.stopPropagation(); // Prevent the click from bubbling up
        setNestedDropdownOpen(prev => (prev === tripType ? '' : tripType));
    };

    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
            <div className="flex flex-col w-[260px] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] h-screen overflow-x">
                <button className="relative text-sm focus:outline-none group">
                    <div className=" mt-4 items-center justify-between w-full text-red-900 text-2xl h-16 px-4 border-gray-300 hover:bg-gray-300">
                        <Link to='/dashboard'>
                            <img src='https://taxiwalataxi.in/static/media/Taxi%20wala%202.c13555739e1553580ab7.png' className='px-5' />
                        </Link>
                    </div>
                </button>
                <div className="flex flex-col flex-grow overflow-auto mt-5">
                    <div className='flex'>
                        <header>
                            <div className="flex mb-2 ">
                                <a className="relative inline-flex items-start mr-2 p-2" href="#0">
                                    <div className="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow" aria-hidden="true">
                                    </div>
                                    <img className="rounded-full" src={Logo} width="60" height="74" alt="User 01" />
                                </a>
                                <div className="mt-1 pr-3 p-2">
                                    <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                                        <h2 className="text-[16px] leading-snug justify-center font-semibold text-[#333]">
                                            John Doe
                                        </h2>
                                    </a>
                                    <div className="flex items-center">
                                        <span className="text-sm font-medium -mt-0.5 mr-1 text-[#777]">Car Admin</span></div>
                                </div>
                            </div>
                        </header>
                    </div>
                    <div className='mt-3'>
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <BiSolidDashboard size={25} />
                            <Link to='/dashboard' onClick={handleMenuClick}> Dashboard </Link>
                        </div>
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] px-4 " onClick={toggleDropdown}>
                            <BiSolidDashboard size={25} />
                            <Link > TripType </Link>
                            <FaAngleDown size={20} className="cursor-pointer" />
                        </div>
                        {dropdownOpen && (
                            <div className="px-5 py-2 text-black">
                                {['onewayTrip', 'roundTrip', 'eightHours', 'tenHours', 'twelveHours', 'airportTrip'].map(tripType => (
                                    <div key={tripType} className="relative">
                                        <div
                                            className={`flex p-2 space-x-7 group hover:bg-[rgb(24,138,226)] ${selectedTripType === tripType ? 'bg-[rgb(24,138,226)] text-white' : ''}`}
                                            onClick={() => handleTripTypeSelect(tripType)}
                                        >
                                            <Link>{tripType.replace(/([A-Z])/g, ' $1')}</Link>
                                            <FaAngleDown
                                                size={20}
                                                className="cursor-pointer"
                                                onClick={(event) => toggleNestedDropdown(tripType, event)}
                                            />
                                        </div>
                                        {nestedDropdownOpen === tripType && (
                                            <div className="left-0 ml-5 mt-2 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
                                                {filteredCars.map((car, index) => (
                                                    <div key={index} className="flex p-2 space-x-7 group hover:bg-[#c7def0]">
                                                        <Link to={`/taxidetail/${car._id}`} onClick={handleMenuClick}>{car.Name}</Link>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}


                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <FaWpforms size={25} />
                            <Link to='/googalemapapi' onClick={handleMenuClick}> GoogaleMapApi Form </Link>
                        </div>

                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <BsLayersFill size={25} />
                            <Link to='/contactform' onClick={handleMenuClick}> Contact Form </Link>
                        </div>
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <RiMemoriesFill size={25} />
                            <Link to='/memoryimg' onClick={handleMenuClick}> Memories Form </Link>
                        </div>
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <FaWpforms size={25} />
                            <Link to='/testimonail' onClick={handleMenuClick}> Testimonial Form </Link>
                        </div>
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <FaWpforms size={25} />
                            <Link to='/blog' onClick={handleMenuClick}> Blog Form </Link>
                        </div>
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <FaWpforms size={25} />
                            <Link to='/cityroute' onClick={handleMenuClick}> CityRoute Form </Link>
                        </div>

                        {/* <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <FaWpforms size={25} />
                            <Link to='/routingcity' onClick={handleMenuClick}> Routes Form </Link>
                        </div> */}
                        <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                            <FaWpforms size={25} />
                            <Link to='/paymentinfo' onClick={handleMenuClick}> Payment Information</Link>
                        </div> 
                        <div>
                            <div className="mt-5 border-2 border-dashed"></div>
                            {allDataTable.map((taxi, index) => (
                                <div key={index} className="flex p-5 space-x-7 group hover:bg-[#c7def0]">
                                    <AiFillCar size={20} />
                                    <Link to={`/taxidetail/${taxi._id}`} onClick={handleMenuClick}>{taxi.Name}</Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Outlet />
            </div>
        </aside>
    );
}

export default Sidebar;
