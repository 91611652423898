import React, { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";

import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactHtmlParser from 'react-html-parser';
import { RiFileEditFill, RiDeleteBin6Fill } from 'react-icons/ri';

const initialValue = {
  name: "",
  long_description: "",
  short_description: "",
  image: [],
  keyword: "",
};

export default function TourForm() {
  const [data, setData] = useState(initialValue);
  const [printData, setPrintData] = useState([]);
  const [edit, setEdit] = useState(false);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://bhadegadiapi.taxiwalataxi.in/api/v1/deleteblog/${id}`, {
        method: "DELETE"
      });
      if (response.ok) {
        setPrintData(values => values.filter(item => item._id !== id));
        fetchPrintData();
        toast.success("Deleted Tour Successfully");
      } else {
        toast.error("Failed to delete Tour");
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to delete Tour");
    }
  };

  const handleUpdate = async () => {
  
    
    try {
      const formDataImg = new FormData();
      data.image.forEach(img => formDataImg.append('image', img));
      formDataImg.append('name', data.name);
      formDataImg.append('keyword', data.keyword);
      formDataImg.append('short_description', data.short_description);
      formDataImg.append('long_description', data.long_description);

      const response = await fetch(`https://bhadegadiapi.taxiwalataxi.in/api/v1/editblog/${data.id}`, {
        method: 'PATCH',
        body: formDataImg,
      });

      console.log('data =>',data);

      const jsonResponse = await response.json();
      if (jsonResponse.status === "success") {
        setData(initialValue);
        setEdit(false);
        toast.success('Updated Tour Data Successfully');
        fetchPrintData();

        window.location.reload();
      } else {
        toast.error(jsonResponse.message || "Failed to update Tour data");
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update Tour data');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataImg = new FormData();
      data.image.forEach(img => formDataImg.append('image', img));
      formDataImg.append('name', data.name);
      formDataImg.append('keyword', data.keyword);
      formDataImg.append('short_description', data.short_description);
      formDataImg.append('long_description', data.long_description);

      const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/addblog', {
        method: 'POST',
        body: formDataImg,
      });

      const jsonResponse = await response.json();
      if (jsonResponse.status === "success") {
        remove();
        toast.success("Data Inserted.");
        fetchPrintData();
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear file input
        }
        window.location.reload();
      } else {
        toast.error(jsonResponse.message || "Failed to add Tour");
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to add Tour');
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setData({ ...data, image: files });

    const imagesContainer = document.getElementById('selected-images');
    imagesContainer.innerHTML = '';
    files.forEach(file => {
      const imgElement = document.createElement('img');
      imgElement.src = URL.createObjectURL(file);
      imgElement.classList.add('w-1/3', 'h-auto', 'mt-2');
      imagesContainer.appendChild(imgElement);
    });
  };

  const remove = async () => {
    setData(initialValue);
  };

  const fetchPrintData = async () => {
    try {
      const response = await fetch("https://bhadegadiapi.taxiwalataxi.in/api/v1/getblog");
      const jsonResponse = await response.json();
      if (jsonResponse?.data?.contacts) {
        setPrintData(jsonResponse.data.contacts);
      } else {
        console.error('Contacts data not found in response', jsonResponse);
        toast.error('No contacts found');
        setPrintData([]);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch tour data');
      setPrintData([]);
    }
  };

  useEffect(() => {
    fetchPrintData();
  }, []);

  const updateUser = (id) => {
    const user = printData.find((user) => user._id === id);
    if (user) {
      // First set the other fields
      setData(prevData => ({
        ...prevData,
        id: user._id, // Ensure you set the id for updating
        name: user.name,
        short_description: user.short_description,
        keyword: user.keyword,
        image: user.image,
      }));
  
      // Then update the long_description after the initial state is set
      setTimeout(() => {
        setData(prevData => ({
          ...prevData,
          long_description: user.long_description
        }));
      }, 0);
      
      setEdit(true); // Enable edit mode
    }
  };
  
  

  return (
    <>
      <main className='main-container'>
        <h1 className='text-black text-[20px] py-3 px-8'>Best Of Our Memories Form.</h1>

        <form onSubmit={handleSubmit}>
          <div className="w-full max-w-5xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="py-2">
                <input
                  className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                  type="text"
                  name="name"
                  placeholder="Blog Name"
                  onChange={handleChange}
                  value={data.name}
                  ref={fileInputRef}
                />
              </div>

              <div className="py-2">
                <input
                  className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                  type="text"
                  name="short_description"
                  placeholder="Short Description"
                  onChange={handleChange}
                  ref={fileInputRef}
                  value={data.short_description}
                />
              </div>

              <div className="col-span-1 md:col-span-2 py-2 text-black">
                <ReactQuill
                  value={data.long_description}
                  onChange={(content) => setData({ ...data, long_description: content })}
                  placeholder="Blog Details"
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                      [{ size: [] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      [{ 'align': [] }],
                      ['link', 'image'],
                      ['clean']
                    ],
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'color', 'background',
                    'bold', 'italic', 'underline', 'strike',
                    'list', 'bullet', 'link',
                    'align', 'image'
                  ]}
                />
              </div>

              <div className="py-2">
                <textarea
                  className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                  type="text"
                  name="keyword"
                  placeholder="Keyword"
                  onChange={handleChange}
                  value={data.keyword}
                  ref={fileInputRef}
                />
              </div>

              <div className="py-2">
                <input
                  className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                  type="file"
                  name="image"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <div id="selected-images" className="flex flex-wrap gap-2 mt-2"></div>
              </div>
            </div>

            <div className="flex justify-center gap-4 mt-4">
              {edit ? (
                <button
                  type="button"
                  className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                  onClick={handleUpdate}
                >
                  Update Tour
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                >
                  Add Tour
                </button>
              )}
              <button
                type="button"
                className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                onClick={remove}
              >
                Clear
              </button>
            </div>
          </div>
        </form>



        <div className="w-full h-screen ">
          <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div className="flex flex-col">
              <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
                <div className="align-middle inline-block w-full  shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400 ">
                  <table className="min-w-full text-center bg-black">
                    <thead className='text-center'>
                      <tr className="bg-gray-50  text-xs leading-4 text-gray-500 uppercase tracking-wider  border-b border-gray-400">
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          ID
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Name
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Short Description
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Long Description
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Image
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200">
                          Edit
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white text-center">
                      {printData?.map((item, i) => (
                        <tr key={i} className='border border-grey-400 text-[12px]'>
                          <td className='text-black border-r border-gray-400'>{i + 1}</td>
                          <td className='text-[#000] text-black border-r border-gray-400'>{item.name}</td>
                          <td className='text-black border-r border-gray-400'>
                            {item.short_description}
                          </td>
                          <td className='text-black border-r border-gray-400'>
                          {ReactHtmlParser(item.long_description)}

                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${item.image[0]}`} className='w-full h-full' alt="Tour" />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap bg-white-200 text-center">
                            <div className="text-sm leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                              <RiFileEditFill size={20} fill='green' onClick={() => updateUser(item._id)} />
                              <RiDeleteBin6Fill size={20} fill='red' onClick={() => handleDelete(item._id)} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>


      </main>
    </>
  );
}
