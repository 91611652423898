import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Header from './Component/Header';
import Sidebar from './Component/Sidebar';
import Home from './Component/Home';  
import CarForm from './Forms/carForm';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CityForm from './Forms/cityForm';
import CarDetailsForm from './Forms/carDetail';
import Memories from './Forms/Memories';
import CarCabForm from './Forms/carCabForm';
import TourForm from './Forms/tourForm';
import ContactForm from './Forms/ContactDetail';
import BookingDetails from './Forms/BookingDetails';
import Login from './Component/Login';
import LocalDropCity from './Forms/Local_DropCityForm';
import LocalDropCityDetails from './Forms/Local_dropCityDetail';
import Blog from './Forms/blog';
import CarDetailsHindustanRentalTaxi from './Forms/CarDetailsHindustanRentalTaxi'
import TripWiseForm from './Forms/TripWiseForm'
import OneWayTripForm from './Forms/OneWayTripForm'
import RoundTripForm from './Forms/RoundWayTripForm'
import Taxidetail from './Forms/taxidetail'
import ContactFormData from './Forms/ContactForm'
import MemoryImg from './Forms/MemoryImg'
import TestimonialForm from './Forms/TestimonialForm';
import AddTaxiDetails from './Forms/addTaxiDetails';
import GoogaleMapApi from './Forms/Googlemapapikey';
import CityRoutesForm from './Forms/CityRoutesForm';
import RoutesCityForm from './Forms/RoutesCityForm';
import PaymentInfo from './Forms/PaymentInfo';


function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  if(window.location.pathname === '/'){
    localStorage.removeItem("token");
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, []); 

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  }

  return (
    <>

      <Router>
        {isLoggedIn ? (
          <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar}  setFormOpen={setFormOpen} formOpen={formOpen}/>
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} setOpenSidebarToggle={setOpenSidebarToggle}/>
            <Routes>
              <Route path="/dashboard" element={<Home />} />
              <Route path='/contactform' element={<ContactFormData/>}  />
              <Route path='/memoryimg' element={<MemoryImg/>}  />
              <Route path='/googalemapapi' element={<GoogaleMapApi/>}  />
              <Route path='/taxidetail/:id' element={<Taxidetail/>}  />
              <Route path='/testimonail' element={<TestimonialForm/>}  />
              <Route path='/blog' element={<Blog/>}  />
             
              <Route path='/addcar' element={<AddTaxiDetails setFormOpen={setFormOpen} formOpen={formOpen}/> }  />
              
              <Route path='/cityroute' element={<CityRoutesForm/>}  />
              <Route path='/routingcity' element={<RoutesCityForm/>}  />
              <Route path='/paymentinfo' element={<PaymentInfo/>}  />
            </Routes>
            <ToastContainer closeButton={false} position="bottom-right" />
          </div>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
            </Routes>
           
          </>
        )}
         <ToastContainer closeButton={false} position="bottom-right" />
      </Router>
    </>
  );
}

export default App;

