import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Ensure axios is installed and imported
import toast from 'react-hot-toast'; // Ensure react-hot-toast is installed and imported

const EditModal = ({ formData, handleClose }) => {
    const [localFormData, setLocalFormData] = useState(formData);
    const [taxiPrint, setTaxiPrint] = useState([]);
    const [imagePreview, setImagePreview] = useState(null); // State for image preview
    const [iconPreview, setIconPreview] = useState(null); // State for icon preview
    const [selectPrint, setSelectPrint] = useState("");

    console.log('localFormData =>', localFormData);

    useEffect(() => {
        fetchTaxiData();
        // Set previews if existing formData has image or icon URLs
        if (formData.Image) {
            setImagePreview(`https://bhadegadiapi.taxiwalataxi.in/uploads/${formData.Image}`);
        }
        if (formData.icon) {
            setIconPreview(`https://bhadegadiapi.taxiwalataxi.in/uploads/${formData.icon}`);
        }
    }, [formData]);

    useEffect(() => {
        fetchTaxiData();
        // Update local form data if formData changes
        setLocalFormData(formData);
        setSelectPrint(formData.Name || ""); // Ensure selectPrint is set correctly
    }, [formData]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        const nameParts = name.split('.');
        if (nameParts.length > 1) {
            setLocalFormData(prevState => ({
                ...prevState,
                [nameParts[0]]: {
                    ...prevState[nameParts[0]],
                    [nameParts[1]]: value
                }
            }));
        } else if (files && files.length > 0) {
            // Update preview based on the file type
            if (name === 'Image') {
                setLocalFormData({ ...localFormData, Image: files[0] });
                setImagePreview(URL.createObjectURL(files[0]));
            } else if (name === 'icon') {
                setLocalFormData({ ...localFormData, icon: files[0] });
                setIconPreview(URL.createObjectURL(files[0]));
            }
        } else {
            setLocalFormData({ ...localFormData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleUpdate();

    };

    const handleUpdate = async (_id) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('Name', selectPrint);
            formDataToSend.append('IsAcCar', localFormData.IsAcCar);
            formDataToSend.append('seats', localFormData.seats);
            formDataToSend.append('BootSpace', localFormData.BootSpace);
            formDataToSend.append('Type', localFormData.Type);
            console.log('localFormData.Type=>', localFormData.Type);


            // Append onewayTrip details
            formDataToSend.append('onewayTrip.isAvailable', localFormData.onewayTrip?.isAvailable || '');
            formDataToSend.append('onewayTrip.actualRatePerKM', localFormData.onewayTrip?.actualRatePerKM || '');
            formDataToSend.append('onewayTrip.discountRatePerKM', localFormData.onewayTrip?.discountRatePerKM || '');

            // Append roundTrip details
            formDataToSend.append('roundTrip.isAvailable', localFormData.roundTrip?.isAvailable || '');
            formDataToSend.append('roundTrip.actualRatePerKM', localFormData.roundTrip?.actualRatePerKM || '');
            formDataToSend.append('roundTrip.discountRatePerKM', localFormData.roundTrip?.discountRatePerKM || '');
            formDataToSend.append('roundTrip.perDayKMLimit', localFormData.roundTrip?.perDayKMLimit || '');

            // Append eightHours details
            formDataToSend.append('eightHours.isAvailable', localFormData.eightHours?.isAvailable || '');
            formDataToSend.append('eightHours.actualRateFix', localFormData.eightHours?.actualRateFix || '');
            formDataToSend.append('eightHours.discountRateFix', localFormData.eightHours?.discountRateFix || '');
            formDataToSend.append('eightHours.perKMRateOneway', localFormData.eightHours?.perKMRateOneway || '');
            formDataToSend.append('eightHours.bookingKMLimit', localFormData.eightHours?.bookingKMLimit || '');

            // Append tenHours details
            formDataToSend.append('tenHours.isAvailable', localFormData.tenHours?.isAvailable || '');
            formDataToSend.append('tenHours.actualRateFix', localFormData.tenHours?.actualRateFix || '');
            formDataToSend.append('tenHours.discountRateFix', localFormData.tenHours?.discountRateFix || '');
            formDataToSend.append('tenHours.perKMRateOneway', localFormData.tenHours?.perKMRateOneway || '');
            formDataToSend.append('tenHours.bookingKMLimit', localFormData.tenHours?.bookingKMLimit || '');

            // Append twelveHours details
            formDataToSend.append('twelveHours.isAvailable', localFormData.twelveHours?.isAvailable || '');
            formDataToSend.append('twelveHours.actualRateFix', localFormData.twelveHours?.actualRateFix || '');
            formDataToSend.append('twelveHours.discountRateFix', localFormData.twelveHours?.discountRateFix || '');
            formDataToSend.append('twelveHours.perKMRateOneway', localFormData.twelveHours?.perKMRateOneway || '');
            formDataToSend.append('twelveHours.bookingKMLimit', localFormData.twelveHours?.bookingKMLimit || '');

            // Append airportTrip details
            formDataToSend.append('airportTrip.isAvailable', localFormData.airportTrip?.isAvailable || '');
            formDataToSend.append('airportTrip.actualRatePerKM', localFormData.airportTrip?.actualRatePerKM || '');
            formDataToSend.append('airportTrip.discountRatePerKM', localFormData.airportTrip?.discountRatePerKM || '');

            // Append files if they exist
            if (localFormData.Image) formDataToSend.append('Image', localFormData.Image);
            if (localFormData.icon) formDataToSend.append('icon', localFormData.icon);

            const response = await axios.patch(
                `https://bhadegadiapi.taxiwalataxi.in/api/v1/updateTaxi/${formData._id}`,
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log('response =>',response);
            
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                handleClose();
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            console.log(err);
            toast.error('Error updating item. Please try again.');
        }
    };

    const fetchTaxiData = async () => {
        try {
            const response = await axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/gettaxidetail');
            if (response.data.status === 'success') {
                setTaxiPrint(response.data.data);
            } else {
                toast.error('Failed to fetch taxi data');
            }
        } catch (error) {
            console.error('Error fetching taxi data', error);
            toast.error('Error fetching taxi data');
        }
    };


    const fatch = async () => {
        try {
            const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/allTaxi";
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const response = await fetch(addRecordEndpoint, options);
            const jsonResponse = await response.json();
            setTaxiPrint(jsonResponse?.data);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        fatch();

    }, []);

    const onOptionChangeHandler = (event) => {
        const selectedName = event.target.value;
        setSelectPrint(selectedName);
        setLocalFormData(prevState => ({
            ...prevState,
            Name: selectedName
        }));
    };

    return (

        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex py-2 justify-center overflow-auto">
            <div className="bg-white p-4 md:p-6 lg:p-8 xl:p-10 rounded-lg shadow-lg w-full max-w-7xl ">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-black">Edit Taxi Details In {localFormData.Name}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-2  text-black">
                        <div className="col-span-1  ">
                            <label className="block text-gray-600 font-bold">Taxi Name</label>
                            <select value={selectPrint} onChange={onOptionChangeHandler} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-[#000]">
                                {taxiPrint?.map((option, index) => (
                                    <option key={index} name="Name" value={option.taxi_name} className='text-[#000]'>
                                       
                                        {option?.taxi_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-1 ">
                            <label className="block text-gray-600 font-bold">Taxi AC</label>
                            <select name="IsAcCar" value={localFormData.IsAcCar} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-[#000]">
                                <option value="">Select AC Option</option>
                                <option value="AC">AC</option>
                                <option value="Non AC">Non AC</option>
                            </select>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">Seats</label>
                            <input type="number" name="seats" value={localFormData.seats} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                        </div>
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">Boot Space</label>
                            <input type="text" name="BootSpace" value={localFormData.BootSpace} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8 mt-4 text-black">
                        <div className="col-span-1">
                            <label className="block text-gray-600 font-bold">Type</label>
                            <select name="Type" value={localFormData.Type} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-[#000]">
                                <option value="">Type</option>
                                <option value="Sedan">Sedan</option>
                                <option value="Crysta">Crysta</option>
                                <option value="17-Seat">17-Seat</option>
                                <option value="14-Seat">14-Seat</option>
                                <option value="20-Seat">20-Seat</option>
                                <option value="Winger">Winger</option>
                                <option value="Urbania">Urbania</option>
                            </select>
                        </div>
                        <div className="col-span-1 flex items-center space-x-4">
                            <div className="w-full">
                                <label className="block text-gray-600 font-bold">Image</label>
                                <input type="file" name="Image" onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            {imagePreview && <img src={imagePreview} alt="Image preview" className="w-24 h-auto" />}
                        </div>
                        <div className="col-span-1 flex items-center space-x-4">
                            <div className="w-full">
                                <label className="block text-gray-600 font-bold">Icon</label>
                                <input type="file" name="icon" onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            {iconPreview && <img src={iconPreview} alt="Icon preview" className="w-10 h-auto" />}
                        </div>
                    </div>

                    <div className="mt-8 mb-8"></div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4  text-black ">
                        {/* Oneway Trip */}
                        <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                            <h5 className='text-black py-3 font-bold'>OnewayTrip Form:</h5>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                <select name="onewayTrip.isAvailable" value={localFormData.onewayTrip.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md">
                                    <option value="">Select Availability</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate</label>
                                <input type="number" name="onewayTrip.actualRatePerKM" value={localFormData.onewayTrip?.actualRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate</label>
                                <input type="number" name="onewayTrip.discountRatePerKM" value={localFormData.onewayTrip?.discountRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                        </div>

                        {/* Round Trip */}
                        <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                            <h5 className='text-black py-3 font-bold'>Round Trip Form:</h5>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm">Availability</label>
                                <select name="roundTrip.isAvailable" value={localFormData.roundTrip?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md">
                                    <option value="">Select Availability</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate</label>
                                <input type="number" name="roundTrip.actualRatePerKM" value={localFormData.roundTrip?.actualRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate Per KM Limit</label>
                                <input type="number" name="roundTrip.discountRatePerKM" value={localFormData.roundTrip?.discountRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Booking Km Limit</label>
                                <input type="number" name="roundTrip.perDayKMLimit" value={localFormData.roundTrip?.perDayKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                        </div>

                        {/* Eight Hours */}
                        <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                            <h5 className='text-black py-3 font-bold'>Eight Hours Form:</h5>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                <select name="eightHours.isAvailable" value={localFormData.eightHours?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md">
                                    <option value="">Select Availability</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate</label>
                                <input type="number" name="eightHours.actualRateFix" value={localFormData.eightHours?.actualRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate</label>
                                <input type="number" name="eightHours.discountRateFix" value={localFormData.eightHours?.discountRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Per KM Limit</label>
                                <input type="number" name="eightHours.perKMRateOneway" value={localFormData.eightHours?.perKMRateOneway || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                            <div className="col-span-1 mb-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Booking Km Limit </label>
                                <input type="number" name="eightHours.bookingKMLimit" value={localFormData.eightHours?.bookingKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                        </div>

                        {/* Ten Hours */}
                        <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                            <h5 className='text-black py-3 font-bold'>Ten Hours Form:</h5>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                <select name="tenHours.isAvailable" value={localFormData.tenHours?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md">
                                    <option value="">Select Availability</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate</label>
                                <input type="number" name="tenHours.actualRateFix" value={localFormData.tenHours?.actualRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate</label>
                                <input type="number" name="tenHours.discountRateFix" value={localFormData.tenHours?.discountRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>

                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Per KM Limit</label>
                                <input type="number" name="tenHours.perKMRateOneway" value={localFormData.tenHours?.perKMRateOneway || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Booking Km Limit </label>
                                <input type="number" name="tenHours.bookingKMLimit" value={localFormData.tenHours?.bookingKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                        </div>

                        {/* Twelve Hours */}
                        <div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                            <h5 className='text-black py-3 font-bold'>Twelve Hours Form:</h5>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                <select name="twelveHours.isAvailable" value={localFormData.twelveHours?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md">
                                    <option value="">Select Availability</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate</label>
                                <input type="number" name="twelveHours.actualRateFix" value={localFormData.twelveHours?.actualRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate</label>
                                <input type="number" name="twelveHours.discountRateFix" value={localFormData.twelveHours?.discountRateFix || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />
                            </div>


                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Per KM Limit</label>
                                <input type="number" name="twelveHours.perKMRateOneway" value={localFormData.twelveHours?.perKMRateOneway || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                            <div className="col-span-1">
                                <label className="block text-gray-600 font-bold text-sm py-1">Booking Km Limit </label>
                                <input type="number" name="twelveHours.bookingKMLimit" value={localFormData.twelveHours?.bookingKMLimit || ''} onChange={handleChange} className="w-full border border-gray-400 p-2 focus:outline-none rounded-md" />

                            </div>
                        </div>

{/* Airport Trip */}
<div className="border-2 border-dashed px-2 border-gray-400 rounded-lg shadow-md">
                                    <h5 className='text-black py-3 font-bold text-sm py-1'>Airport Form : </h5>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Availability</label>
                                        <select name="airportTrip.isAvailable" value={localFormData.airportTrip?.isAvailable || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md">
                                            <option value="">Availability</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Actual Rate </label>
                                        <input type="number" name="airportTrip.actualRatePerKM" value={localFormData.airportTrip?.actualRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-gray-600 font-bold text-sm py-1">Discount Rate </label>
                                        <input type="number" name="airportTrip.discountRatePerKM" value={localFormData.airportTrip?.discountRatePerKM || ''} onChange={handleChange} className="w-full border border-gray-400 p-1 focus:outline-none rounded-md" />
                                    </div>


                                </div>


                        {/* Submit Button */}
                        <div className="flex justify-end mt-1">
                            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none">Save</button>
                            <button type="button" onClick={handleClose} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none ml-2">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>


    );
};

export default EditModal;
