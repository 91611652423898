import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { RiFileEditFill, RiDeleteBin6Fill } from 'react-icons/ri';

const initialValue = {
  cityname: '',
};

export default function CityRoutesForm() {
  const [data, setData] = useState(initialValue);
  const [printData, setPrintData] = useState([]);
  const [edit, setEdit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://bhadegadiapi.taxiwalataxi.in/api/v1/deletecity/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        // Instead of fetching again, just filter the item out
        setPrintData((values) => values.filter((item) => item._id !== id)); // Use _id instead of id
        toast.success('Deleted City Successfully');
      } else {
        throw new Error('Failed to delete city');
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete City');
    }
  };
  

  const handleUpdate = async () => {
    try {
      const updateEndpoint = `https://bhadegadiapi.taxiwalataxi.in/api/v1/editblog/${data._id}`;
      
      const response = await fetch(updateEndpoint, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cityname: data.cityname,
        }),
      });

      const jsonResponse = await response.json();
      if (jsonResponse.status === 'success') {
        setEdit(false);
        setData(initialValue);
        toast.success('Updated City Data Successfully');
        fetchPrintData();
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update City data');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const addRecordEndpoint = 'https://bhadegadiapi.taxiwalataxi.in/api/v1/addcity';
      
      const response = await fetch(addRecordEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cityname: data.cityname,
        }),
      });

      const jsonResponse = await response.json();
      if (jsonResponse.status === 'success') {
        toast.success(jsonResponse.message);
        fetchPrintData();
        setData(initialValue);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to add City');
    }
  };

  const fetchPrintData = async () => {
    try {
      const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/getroute');
      const jsonResponse = await response.json();
      setPrintData(jsonResponse?.data.cityname || []);
    } catch (err) {
      // console.error(err);
      // toast.error('Failed to fetch city data');
    }
  };

  useEffect(() => {
    fetchPrintData();
  }, []);

  const updateUser = async (id) => {
    try {
      const user = printData.find((user) => user._id === id);
      setData({ cityname: user.cityname });
      setEdit(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <main className="main-container">
        <h1 className="text-black text-[20px] py-3 px-8">City Form</h1>

        <form onSubmit={handleSubmit}>
          <div className="w-full max-w-lg mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
            {/* City Name Field */}
            <div className="py-2">
              <input
                className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                type="text"
                name="cityname"
                placeholder="City Name"
                onChange={handleChange}
                value={data.cityname}
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-center gap-4 mt-4">
              {edit ? (
                <button
                  type="button"
                  className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-[#188ae2] text-white hover:bg-[#1899ff] rounded-lg px-4 py-2"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>

        {/* Display Data Table */}
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg p-2 mt-4 mx-4 ">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
              <th className="py-3 px-6">Id</th>
                <th className="py-3 px-6">City</th>
                <th className="py-3 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {printData.length > 0 ? (
                printData.map((user,i) => (
                  <tr key={user._id} className="bg-white border-b">
                      <td className="py-4 px-6">{i+1}</td>
                    <td className="py-4 px-6">{user.cityname}</td>
                    <td className="py-4 px-6 flex gap-2">
                      {/* <RiFileEditFill
                        className="cursor-pointer text-lg text-blue-500"
                        onClick={() => updateUser(user._id)}
                      /> */}
                      <RiDeleteBin6Fill
                        className="cursor-pointer text-lg text-red-500"
                        onClick={() => handleDelete(user._id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="bg-white border-b">
                  <td colSpan="2" className="py-4 px-6 text-center">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
}
