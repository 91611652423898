import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiDeleteBin6Fill, RiFileEditFill } from 'react-icons/ri';

const GoogalemapApi = () => {
    const [formData, setFormData] = useState({
        googlemapapikey: '',
    });
    const [contactId, setContactId] = useState('');
    const [contactList, setContactList] = useState([]); // Initialize as an empty array

    useEffect(() => {
        fetchContactList(); // Call the function to fetch the list
    }, []);

    const fetchContactList = () => {
        // Fetch list of contacts
        axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getapikey')
            .then(response => {
                setContactList(response.data.data.taxis || []); // Ensure data is an array
            })
            .catch(error => {
                console.error('Error fetching contact list:', error);
            });
    };

    const handleEdit = (contact) => {
        setContactId(contact._id);
        setFormData({
            googlemapapikey: contact.googlemapapikey,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        // Insert contact data
        axios.post(`https://bhadegadiapi.taxiwalataxi.in/api/v1/addapikey`, formData)
            .then(response => {
                console.log('API Key added successfully:', response.data);
                fetchContactList(); // Fetch the updated list after adding a new key
                handleReset(); // Clear form after successful addition
            })
            .catch(error => {
                console.error('Error adding API key:', error);
            });
    };

    const handleReset = () => {
        setFormData({
            googlemapapikey: '',
        });
        setContactId('');
    };

    return (
        <main className='main-container'>
            <div className="w-full max-w-4xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
                <form>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">GoogleMapApi Key</label>
                            <input
                                type="text"
                                name="googlemapapikey"
                                value={formData.googlemapapikey}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter Api Key"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 justify-center mt-4">
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-full h-screen">
                <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
                            <div className="align-middle inline-block w-full shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400">
                                <table className="min-w-full text-center">
                                    <thead className='text-center'>
                                        <tr className="bg-gray-50 text-xs leading-4 text-gray-700 uppercase tracking-wider border-b border-gray-400">
                                            <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">ID</th>
                                            <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">API Key</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white text-center">
                                        {contactList.length > 0 ? contactList.map((contact, i) => (
                                            <tr key={i} className="border border-grey-400">
                                                <td className="text-black border-r border-gray-400">{i + 1}</td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400 font-normal">
                                                    <div className="text-sm leading-5 text-black" style={{ fontWeight: "400" }}>{contact.googlemapapikey}</div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="3" className="px-6 py-4">No contacts found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default GoogalemapApi;
