import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MemoryImg = () => {
    const [placeName, setPlaceName] = useState('');
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [memoryImgs, setMemoryImgs] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);

        // Generate previews for selected files
        const previews = files.map(file => URL.createObjectURL(file));
        setImagePreviews(previews);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('placeName', placeName);

        for (let i = 0; i < images.length; i++) {
            formData.append('memoryImgs', images[i]);
        }

        try {
            const response = await axios.post('https://bhadegadiapi.taxiwalataxi.in/api/v1/addmemoryimg', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Images uploaded successfully:', response.data);
            handleReset(); // Clear form after successful upload
            fetchMemoryImgs(); // Fetch the latest data
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleReset = () => {
        setPlaceName('');
        setImages([]);
        setImagePreviews([]);
        document.querySelector('input[type="file"]').value = null;
    };

    const fetchMemoryImgs = async () => {
        try {
            const response = await axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getmemoryimg');
            setMemoryImgs(response.data.data);
        } catch (error) {
            console.error('Error fetching memory images:', error);
        }
    };

    useEffect(() => {
        fetchMemoryImgs();
    }, []);

    const handleAddImages = (place) => {
        setPlaceName(place.placeName);
    };

    const handleDeleteImage = async (id, imageName) => {
        try {
            await axios.delete(`https://bhadegadiapi.taxiwalataxi.in/api/v1/deletememoryimg/${id}/${imageName}`);
            fetchMemoryImgs(); // Refresh data after deletion
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleDeleteData = async (id, imageName) => {
        try {
            await axios.delete(`https://bhadegadiapi.taxiwalataxi.in/api/v1/deletedata/${id}`);
            fetchMemoryImgs(); // Refresh data after deletion
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };
    
    return (
        <main className='main-container'>
            <div className="w-full max-w-4xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Place Name</label>
                            <input
                                type="text"
                                name="placeName"
                                value={placeName}
                                onChange={(e) => setPlaceName(e.target.value)}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter place name"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Images</label>
                            <input
                                type="file"
                                name="memoryImgs"
                                multiple
                                onChange={handleImageChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                            />
                            <div className="mt-2 flex flex-wrap gap-2">
                                {imagePreviews.map((preview, index) => (
                                    <img
                                        key={index}
                                        src={preview}
                                        alt={`preview-${index}`}
                                        className="w-20 h-20 object-cover border rounded-md"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 justify-center mt-4">
                        <button
                            type="submit"
                            className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-full max-w-[70rem] mx-auto pt-3 bg-gray-100 mt-8 shadow-2xl rounded-lg border border-gray-300">
                <h2 className="text-3xl ml-6 font-bold text-gray-900">Memories Images</h2>
                <div className="p-6">
                    <div className="text-black">
                        <div className="bg-gray-200 p-4 rounded-lg shadow-md ">
                            {memoryImgs.map((memory, index) => (
                                <div key={index} className="bg-white p-4 border rounded-lg shadow-md my-3">
                                    <h2 className="text-xl font-semibold mb-2">{memory.placeName}</h2>
                                    <div className="grid gap-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8">
                                        {memory.memoryImgs.map((img, imgIndex) => (
                                            <div key={imgIndex} className="relative">
                                                <img
                                                    src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${img}`} // Adjust path as necessary
                                                    alt={`image-${imgIndex}`}
                                                    className="w-full h-32 object-cover border rounded-md"
                                                />
                                                <button
                                                    onClick={() => handleDeleteImage(memory._id, img)}
                                                    className="absolute bg-red-600 text-white rounded-full p-1 w-6 h-6 top-1 right-1 flex items-center justify-center"
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex gap-4 mt-4">
                                        <button
                                            type="button"
                                            onClick={() => handleAddImages(memory)}
                                            className="bg-[#188ae2] text-[#fff] hover:bg-[#fff] hover:text-[#188ae2] border border-[#188ae2] rounded-lg px-4 py-2"
                                        >
                                            Add Images
                                        </button>

                                        <button
                                            type="button"
                                            onClick={() => handleDeleteData(memory._id)}  // Pass the necessary ID or place name
                                            className="bg-red-600 text-white hover:bg-red-700 border border-red-600 rounded-lg px-4 py-2"
                                        >
                                            Delete
                                        </button>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default MemoryImg;








