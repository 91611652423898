import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestimonialForm = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [testimonials, setTestimonials] = useState([]);

  // Fetch testimonials data when the component mounts
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getTestimonial');
        setTestimonials(response.data.data.testimonials);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    if (image) {
      formData.append('image', image);
    }
    if (video) {
      formData.append('video', video);
    }

    try {
      const response = await axios.post('https://bhadegadiapi.taxiwalataxi.in/api/v1/addtestimonial', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Testimonial added successfully:', response.data);
      handleReset(); // Clear form after successful upload
      setTestimonials([...testimonials, response.data.data.testimonial]); // Update testimonials list
    } catch (error) {
      console.error('Error adding testimonial:', error);
    }
  };

  const handleReset = () => {
    setName('');
    setImage(null);
    setVideo(null);
    document.querySelector('input[name="image"]').value = null;
    document.querySelector('input[name="video"]').value = null;
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bhadegadiapi.taxiwalataxi.in/api/v1/deletetestimonial/${id}`);
      setTestimonials(testimonials.filter(testimonial => testimonial._id !== id)); // Update testimonials list
      console.log('Testimonial deleted successfully');
    } catch (error) {
      console.error('Error deleting testimonial:', error);
    }
  };

  return (
    <main className='main-container'>
      <div className="w-full max-w-4xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-gray-600 font-bold mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                placeholder="Enter Your Name"
              />
            </div>
            <div>
              <label className="block text-gray-600 font-bold mb-2">Image</label>
              <input
                type="file"
                name="image"
                onChange={(e) => setImage(e.target.files[0])} // Handle file upload
                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
              />
            </div>
            <div>
              <label className="block text-gray-600 font-bold mb-2">Video</label>
              <input
                type="file"
                name="video"
                accept="video/*"
                onChange={(e) => setVideo(e.target.files[0])} // Handle file upload
                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 justify-center mt-4">
            <button
              type="submit"
              className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
            >
              Reset
            </button>
          </div>
        </form>+
      </div>
      <div className="w-full h-screen">
        <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
              <div className="align-middle inline-block w-full shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400">
                <table className="min-w-full text-center">
                  <thead className='text-center'>
                    <tr className="bg-gray-50 text-xs leading-4 text-gray-700 uppercase tracking-wider border-b border-gray-400">
                      <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">ID</th>
                      <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">Name</th>
                      <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">Image</th>
                      <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">Video</th>
                      <th className="py-3 font-medium bg-gray-200">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white text-center">
                    {testimonials.map((testimonial, index) => (
                      <tr key={index} className="border-b border-gray-400 text-black">
                        <td className="px-6 py-4 border-r border-gray-400">{index+1}</td>
                        <td className="px-6 py-4 border-r border-gray-400">{testimonial.name}</td>
                        <td className="px-6 py-4 border-r border-gray-400">
                          {testimonial.image && (
                            <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${testimonial.image}`} alt="testimonial" className="w-32x h-28 mx-auto" />
                          )}
                        </td>
                        <td className="px-6 py-4 border-r border-gray-400">
                          {testimonial.video && (
                            <video width="100" height="100" controls>
                              <source src={`https://bhadegadiapi.taxiwalataxi.in/uploads/videos/${testimonial.video}`} type="video/mp4" />
                            </video>
                          )}
                        </td>
                        <td className="px-6 py-4">
                        <button
                            onClick={() => handleDelete(testimonial._id)}
                            className="bg-red-500 text-white px-4 py-2 rounded"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default TestimonialForm;
