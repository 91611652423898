import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiDeleteBin6Fill, RiFileEditFill } from 'react-icons/ri';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        address: '',
        phoneno1: '',
        phoneno2: '',
        cmpName: '',
        email: '',
        cmpWebsite: ''
    });
    const [contactId, setContactId] = useState('');
    const [contactList, setContactList] = useState([]);

    useEffect(() => {
        // Fetch the list of contacts
        axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getcontact')
            .then(response => {
                setContactList(response.data.data.contacts);
            })
            .catch(error => {
                console.error('Error fetching contact list:', error);
            });
    }, []);

    // Populate form for editing
    const handleEdit = (contact) => {
        setContactId(contact._id); // Store the contact's ID for update
        setFormData({
            address: contact.address,
            phoneno1: contact.phoneno1,
            phoneno2: contact.phoneno2,
            cmpName: contact.cmpName,
            email: contact.email,
            cmpWebsite: contact.cmpWebsite
        });
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Submit updated data
    const handleSubmit = () => {
        axios.patch(`https://bhadegadiapi.taxiwalataxi.in/api/v1/updatecontact/${contactId}`, formData)
            .then(response => {
                console.log('Contact updated successfully:', response.data);
                const updatedContactList = contactList.map(contact =>
                    contact._id === contactId ? { ...contact, ...formData } : contact
                );

                setContactList(updatedContactList); 
                setFormData({
                    address: '',
                    phoneno1: '',
                    phoneno2: '',
                    cmpName: '',
                    email: '',
                    cmpWebsite: ''
                });
            })
            .catch(error => {
                console.error('Error updating contact:', error);
            });
    };

    // Reset the form
    const handleReset = () => {
        setFormData({
            address: '',
            phoneno1: '',
            phoneno2: '',
            cmpName: '',
            email: '',
            cmpWebsite: ''
        });
        setContactId('');
    };

    return (
        <main className='main-container'>
            <div className="w-full max-w-4xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
                <form>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {/* Input fields */}
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Address</label>
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter address"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Phone No 1</label>
                            <input
                                type="text"
                                name="phoneno1"
                                value={formData.phoneno1}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter phone number 1"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Phone No 2</label>
                            <input
                                type="text"
                                name="phoneno2"
                                value={formData.phoneno2}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter phone number 2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Company Name</label>
                            <input
                                type="text"
                                name="cmpName"
                                value={formData.cmpName}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter company name"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter email address"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-bold mb-2">Company Website</label>
                            <input
                                type="url"
                                name="cmpWebsite"
                                value={formData.cmpWebsite}
                                onChange={handleChange}
                                className="w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]"
                                placeholder="Enter company website URL"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 justify-center mt-4">
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] border border-[#188ae2] rounded-lg px-4 py-2"
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-full h-screen">
                <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
                            <div className="align-middle inline-block w-full shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400">
                                <table className="min-w-full text-center">
                                    <thead className='text-center'>
                                        <tr className="bg-gray-50 text-xs leading-4 text-gray-700 uppercase tracking-wider border-b border-gray-400">
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">ID</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Address</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Phone No 1</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Phone No 2</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Company Name</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Email</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200 border-r border-gray-400">Company Website</th>
                                            <th className="px-3 py-3 font-medium bg-gray-200">Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white text-center">
                                        {contactList.map((contact, i) => (
                                            <tr key={i} className="border border-grey-400">
                                                <td className="text-black border-r border-gray-400">{i + 1}</td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400 font-normal">
                                                    <div className="text-sm leading-5 text-black ">{contact.address}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                    <div className="text-sm leading-5 text-black ">{contact.phoneno1}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                    <div className="text-sm leading-5 text-black ">{contact.phoneno2}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                    <div className="text-sm leading-5 text-black ">{contact.cmpName}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                    <div className="text-sm leading-5 text-black ">{contact.email}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                                                    <div className="text-sm leading-5 text-black ">{contact.cmpWebsite}</div>
                                                </td>
                                                <td className="text-black px-6 py-4 whitespace-no-wrap border-gray-400">
                                                    <button onClick={() => handleEdit(contact)} className="mr-2">
                                                        <RiFileEditFill size={20} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {!contactList.length && (
                                    <div className="text-center p-4 font-medium">No contacts available</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ContactForm;


// import React, { useState } from 'react';

// const ContactForm = () => {
//   const [imageURL, setImageURL] = useState('');
//   const cloudName = 'dhrm9tokh';

//   // Function to upload the image to Cloudinary
//   const uploadImage = async (file) => {
//     const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('upload_preset', 'carimg'); // Use your correct upload preset
  
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         body: formData,
//       });
//       const data = await response.json();
//       if (response.ok) {
//         setImageURL(data.secure_url); // Set the image URL
//       } else {
//         console.error('Upload failed:', data); // Log error message from Cloudinary
//       }
//     } catch (error) {
//       console.error('Upload failed:', error);
//     }
//   };
  
  

//   // Function to share on WhatsApp
//   const handleWhatsAppShare = () => {
//     if (!imageURL) return;

//     const message = 'Check out this amazing car!';
//     const encodedMessage = encodeURIComponent(`${message} ${imageURL}`);
//     const waLink = `https://api.whatsapp.com/send?text=${encodedMessage}`;
//     window.open(waLink, '_blank'); // Open WhatsApp Web in a new tab
//   };

//   // Handle file selection
//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       uploadImage(file); // Upload the selected image
//     }
//   };

//   return (
//     <main className='main-container'>
//       <div className="w-full max-w-4xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
//         <h1>Contact Form</h1>

//         {/* File input for image upload */}
//         <input type="file" accept="image/*" onChange={handleFileChange} className="mt-4" />

//         {/* Display the uploaded image if available */}
//         {imageURL && (
//           <div className="mt-4 flex justify-center">
//             <img src={imageURL} alt="Uploaded" className="rounded-xl shadow-md mb-4 w-1/2" />
//           </div>
//         )}

//         {/* Share Button */}
//         {imageURL && (
//           <div className="mt-4 flex justify-center">
//             <button
//               onClick={handleWhatsAppShare}
//               className="bg-green-500 text-white px-4 py-2 rounded-md"
//             >
//               Share on WhatsApp
//             </button>
//           </div>
//         )}
//       </div>
//     </main>
//   );
// };

// export default ContactForm;












